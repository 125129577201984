import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Error from "./pages/error";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Soil from "./pages/Soil";
import Asphalt from "./pages/Asphalt";
import Concrete from "./pages/Concrete";
import Geophysical from "./pages/Geophysical";
import Projects from "./pages/Projects";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/soil" element={<Soil />} />
        <Route path="/asphalt" element={<Asphalt />} />
        <Route path="/concrete" element={<Concrete />} />
        <Route path="/geophysical" element={<Geophysical />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/TermsOfUse" element={<TermsOfUse />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
