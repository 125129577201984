import React, { useState, useEffect } from "react";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY;
      setIsVisible(scrollY > 100);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer-wrapper footer-layout7">
        <div className="widget-area space-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6 col-xl-3">
                <div className="widget footer-widget">
                  <div className="th-widget-about">
                    <div className="about-logo">
                      <a href="/">
                        <img alt="" src="images/logo-loader.png" />
                      </a>
                    </div>
                    <p className="about-text">
                      Integrity Materials Testing (IMT) is a locally owned
                      construction materials testing company serving the Tampa
                      Bay area.
                    </p>
                    <div className="th-social">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/Integritymaterialstesting"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/integrity-mt"
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">QUICK LINKS</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/services">Services</a>
                      </li>
                      <li>
                        <a href="/projects">Projects</a>
                      </li>
                      <li>
                        <a href="/careers">Careers</a>
                      </li>
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">SERVICES</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <a href="/soil">Soil Testing</a>
                      </li>
                      <li>
                        <a href="/geophysical">Geophysical Testing</a>
                      </li>
                      <li>
                        <a href="/concrete">Concrete Testing</a>
                      </li>
                      <li>
                        <a href="/asphalt">Asphalt Testing</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_contact footer-widget">
                  <h3 className="widget_title">Get in touch!</h3>
                  <div className="th-widget-contact">
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-location-dot" />
                      </div>
                      <p className="info-box_text">
                        <a href="https://maps.app.goo.gl/2tD3gXqawSEsdn2H7">
                          6225 Old Pasco Rd, Wesley Chapel, FL 33544
                        </a>
                      </p>
                    </div>
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-envelope" />
                      </div>
                      <a
                        className="info-box_link"
                        href="mailto:info@integrity-mt.com"
                      >
                        info@integrity-mt.com
                      </a>
                    </div>
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-phone" />
                      </div>
                      <a className="info-box_link" href="tel:+1-813-994-1164">
                        +1 (813) 994 1164
                      </a>
                    </div>
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-clock" />
                      </div>
                      <p className="footer-text">
                        Mon - Friday: 7am - 5pm
                        <br />
                        ​​Saturday: Closed
                        <br />
                        ​Sunday: Closed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <p className="copyright-text">
                  Copyright <i className="fal fa-copyright" /> 2025{" "}
                  <a href="/">Integrity Materials Testing</a>. All Rights
                  Reserved.
                </p>
              </div>
              <div className="col-lg-6 text-end d-none d-lg-block">
                <div className="footer-links">
                  <ul>
                    <li>
                      <a href="/PrivacyPolicy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/TermsOfUse">Terms of Use</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className={`scroll-top ${isVisible ? "show" : ""}`}
        onClick={scrollToTop}
      >
        <svg
          className="progress-circle svg-content"
          height="100%"
          viewBox="-1 -1 102 102"
          width="100%"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "307.919",
            }}
          ></path>
        </svg>
      </div>
    </>
  );
}

export default Footer;
