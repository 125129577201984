import React from "react";
import ErrorImg from "../images/Error.jpg";

function Error() {
  return (
    <section className="space">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="error-page text-center">
              <div className="error-content">
                <div className="error-img mb-50">
                  <img src={ErrorImg} alt="404 image" />
                </div>
                <h2 className="error-title mb-20">
                  <span className="text-theme">OooPs!</span> Page Not Found
                </h2>
                <p className="error-text mb-30">
                  Oops! The page you are looking for does not exist. It might
                  have been moved or deleted.
                </p>
                <a href="/" className="th-btn">
                  <i className="fa-solid fa-home me-2" /> Back To Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error;
