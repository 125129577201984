import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="space container mx-auto p-6 text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Integrity Materials Testing values your privacy. This Privacy Policy
        outlines how we collect, use, and protect your information when you
        visit our website.
      </p>

      <h2 className="text-2xl font-semibold mt-6">1. Information We Collect</h2>
      <p className="mb-4">
        We may collect personal information such as your name, email address,
        phone number, and other relevant details when you contact us or use our
        services.
      </p>

      <h2 className="text-2xl font-semibold mt-6">
        2. How We Use Your Information
      </h2>
      <p className="mb-4">
        Your information is used to provide and improve our services,
        communicate with you, and comply with legal obligations. We do not sell
        or share your personal data with third parties for marketing purposes.
      </p>

      <h2 className="text-2xl font-semibold mt-6">3. Data Security</h2>
      <p className="mb-4">
        We take appropriate security measures to protect your personal data from
        unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h2 className="text-2xl font-semibold mt-6">
        4. Cookies and Tracking Technologies
      </h2>
      <p className="mb-4">
        Our website may use cookies to enhance user experience. You can choose
        to disable cookies through your browser settings.
      </p>

      <h2 className="text-2xl font-semibold mt-6">5. Third-Party Links</h2>
      <p className="mb-4">
        Our website may contain links to third-party sites. We are not
        responsible for their privacy practices and encourage you to review
        their policies.
      </p>

      <h2 className="text-2xl font-semibold mt-6">6. Changes to This Policy</h2>
      <p className="mb-4">
        We reserve the right to update this Privacy Policy at any time. Any
        changes will be posted on this page.
      </p>

      <h2 className="text-2xl font-semibold mt-6">7. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us
        at info@integrity-mt.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
