import React from "react";

const TermsOfUse = () => {
  return (
    <div className="space container mx-auto p-6 text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>
      <p className="mb-4">
        Welcome to Integrity Materials Testing. By accessing our website, you
        agree to comply with and be bound by the following terms and conditions.
        If you do not agree with any part of these terms, please do not use our
        website.
      </p>

      <h2 className="text-2xl font-semibold mt-6">1. Use of Our Website</h2>
      <p className="mb-4">
        This website is intended for informational purposes regarding the
        services offered by IMT. Any unauthorized use of this website or its
        content is strictly prohibited.
      </p>

      <h2 className="text-2xl font-semibold mt-6">
        2. Services and Information
      </h2>
      <p className="mb-4">
        IMT provides construction materials testing services to public and
        private industries. While we strive to keep information accurate and
        up-to-date, we do not guarantee its completeness or accuracy.
      </p>

      <h2 className="text-2xl font-semibold mt-6">3. Intellectual Property</h2>
      <p className="mb-4">
        All content on this website, including text, images, and logos, is the
        property of IMT and is protected by copyright laws. You may not use,
        reproduce, or distribute any content without written permission.
      </p>

      <h2 className="text-2xl font-semibold mt-6">
        4. Limitation of Liability
      </h2>
      <p className="mb-4">
        IMT is not liable for any direct, indirect, incidental, or consequential
        damages resulting from the use or inability to use our website or
        services.
      </p>

      <h2 className="text-2xl font-semibold mt-6">5. Changes to Terms</h2>
      <p className="mb-4">
        We reserve the right to modify these terms at any time. Changes will be
        effective immediately upon posting on this page.
      </p>

      <h2 className="text-2xl font-semibold mt-6">6. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms of Use, please contact us at
        info@integrity-mt.com.
      </p>
    </div>
  );
};

export default TermsOfUse;
